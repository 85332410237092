package net.oc_soft

import kotlin.js.Promise

import kotlinx.browser.window
import kotlinx.browser.document

import org.w3c.dom.HTMLElement
import org.w3c.dom.url.URL


/**
 * application
 */
class App {

     

    /**
     * google login button container html element
     */
    val googleLoginButtonContainer: HTMLElement?
        get() {
            return document.querySelector(".google-login")?.let {
                return it as HTMLElement
            }
        }

    /**
     * callback to handle google credential response
     */
    
    var googleCredentialHdlr: ((dynamic)->Unit)? = null

    /**
     *  bind this object into html elements
     */
    fun bind() {
        val googleCredentialHdlr: ((dynamic)->Unit) = { 
            handleGoogleCredential(it) 
        }
        googleLoginButtonContainer?.let {
            google.accounts.id.initialize(object {
                @JsName("client_id")
                val client_id = net.oc_soft.config.web.id.google as String
                @JsName("callback")
                val callback = googleCredentialHdlr
            })
            this.googleCredentialHdlr = googleCredentialHdlr
            google.accounts.id.renderButton(it,
                object {
                    @JsName("theme")
                    val theme = "outline"

                    @JsName("size")
                    val size = "large"
                }) 
    
            // google.accounts.id.prompt()
        }
    }


    /**
     * detach this object from html elements
     */
    fun unbind() {
        googleCredentialHdlr?.let {
            googleCredentialHdlr = null
        }
    }

    /**
     * callback to handle google credential response
     */
    fun handleGoogleCredential(response : dynamic) {
        window.setTimeout({
            updatePageIfSucceeded(response.credential)
        })
    }


    /**
     * update page if user can be logging in site
     */
    fun updatePageIfSucceeded(bearerToken: String) {

        loginSite(bearerToken).then {
            println(it)
        } 
    }

    


    /**
     * logging into sight
     */
    fun loginSite(
        bearerToken: String): Promise<Any?> {
        val url = URL(window.location.pathname, window.location.origin)

        val searchParams = url.searchParams
        searchParams.append("action", "login")
        searchParams.append("bearer", bearerToken)

        return window.fetch(url).then { it.json() } as Promise<Any?> 
    }
     
    /**
     * run application
     */
    fun run() {
        window.addEventListener(
            "load",
            { bind() }, 
            object {
                @JsName("once")
                val once = true
            })

        window.addEventListener(
            "unload",
            { unbind() },
            object {
                @JsName("once")
                val once = true
            })
    }
}
// vi: se ts=4 sw=4 et:
