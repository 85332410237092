import net.oc_soft.App


/**
 * entry point
 */
fun main(args: Array<String>) {
    val app = App()
    app.run()
}

// vi: ts=4 sw=4 et:
