(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@google/accounts"));
	else if(typeof define === 'function' && define.amd)
		define(["@google/accounts"], factory);
	else if(typeof exports === 'object')
		exports["auth-web-ui"] = factory(require("@google/accounts"));
	else
		root["auth-web-ui"] = factory(root["google"]["accounts"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__83__) {
return 